import { Button } from "@myloc/myloc-gui";
import classNames from "classnames";
import { useState } from "react";
import MoreHoriz from "../../../assets/icons/MoreHoriz";
import OnClickOutside from "../../../utils/OnClickOutside";
import PropType from "prop-types";

import styles from "./Dropmenu.module.scss";
import { useTranslate } from "../../../language/i18n";

const DropMenu = ({ className }) => {
  const [show, setShow] = useState(false);

  const translate = useTranslate();

  return (
    <div className={styles.dropmenu}>
      <OnClickOutside call={() => setShow(false)}>
        <Button className={classNames(styles.button, className)} onClick={() => setShow(!show)}>
          <MoreHoriz />
        </Button>

        {show && (
          <div className={styles.menu}>
            <ul>
              <li>{translate("DELETE")}</li>
            </ul>
          </div>
        )}
      </OnClickOutside>
    </div>
  );
};

DropMenu.propTypes = {
  className: PropType.string,
};

export default DropMenu;
