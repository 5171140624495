import { useEffect, useState } from "react";
import { setError } from "../../../../reducers/dialog/dialogAction";
import { useBankIDCollect, useBankIdStartAuthentication, useQRCode } from "./useBankIdAuthentication";

export const useBankId = () => {
  const [bankIDError, setBankIdError] = useState();

  const { orderRef, autoStartToken, handleAuth, resetAuth, bankIdContent } = useBankIdStartAuthentication(
    setBankIdError,
  );
  const { qrCodeContent, stopQRGeneration } = useQRCode(bankIdContent);
  const { collectedResponse, resetCollect } = useBankIDCollect(bankIdContent, setBankIdError, stopQRGeneration);

  useEffect(() => {
    if (bankIDError) {
      setError({ error: "exception", errorMessage: bankIDError });
    }
  }, [bankIDError, resetAuth, resetCollect]);

  useEffect(() => {
    if (
      collectedResponse.orderRef &&
      (collectedResponse?.hintCode === "userSign" || collectedResponse?.status !== "pending")
    ) {
      stopQRGeneration();
    }

    if (bankIDError) {
      stopQRGeneration();
      resetCollect();
      resetAuth();
    }
  }, [
    bankIDError,
    collectedResponse?.hintCode,
    collectedResponse.orderRef,
    collectedResponse?.status,
    resetAuth,
    resetCollect,
    stopQRGeneration,
  ]);

  const initAuth = () => {
    if (bankIDError) {
      setBankIdError(undefined);
    }
    handleAuth();
  };

  const resetFromUI = () => {
    resetAuth();
    resetCollect();
    stopQRGeneration();
  };

  return { orderRef, autoStartToken, initAuth, qrCodeContent, collectedResponse, resetFromUI };
};
