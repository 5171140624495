import PropType from "prop-types";
import { useSelector } from "react-redux";

import { withPage } from "@myloc/myloc-utils";
import { useTranslate } from "../../language/i18n";
import pages from "../../utils/pages";
import { CONTENT_TYPE } from "../../utils/constants";
import WizardPage, { setActiveStep } from "../shared/WizardPage/WizardPage";
import OrderOverview from "../shared/OrderOverview/OrderOverview";
import AdditionalInfo from "../shared/AdditionalInfo/AdditionalInfo";
import AddressAndContact from "../shared/AddressAndContact/AddressAndContact";
// import Calendar from "./Calendar/Calendar";
import Meeting from "./Meeting";
import OrderInfo from "./OrderInfo";
import Fitting from "./Fitting";
import Aid from "./Aid";
import useOrderWizard from "../../hooks/useOrderWizard";
import { useParams } from "react-router";

const FittingPage = ({ currentActiveStep }) => {
  const queryParams = useParams();
  const translate = useTranslate();
  const { data, setData, stepper, handleNext } = useOrderWizard(pages.FITTING.PATH, {
    contentType: CONTENT_TYPE.FITTING,
  });

  const order = useSelector(state => state.appData.order);

  const steps = [
    {
      title: translate("CHOOSE_FITTING"),
      titleShort: translate("FITTING_TYPE"),
      children: (
        <Fitting
          data={data}
          setData={fittingType => {
            setData({ ...data, fittingType });
          }}
          onNext={handleNext}
        />
      ),
    },
    {
      title: translate("CHOOSE_ONE_AID"),
      titleShort: translate("AID"),
      children: (
        <Aid
          onSelect={product => {
            setData({ ...data, ...product });
          }}
          selected={data.accessories}
          inventoryId={data?.product?.id}
          clientId={order?.receiver?.id}
          onNext={handleNext}
          data={data}
          setData={product => {
            setData({ ...data, ...product });
          }}
        />
      ),
    },
    {
      title: translate("ADD_ADDITIONAL_INFO"),
      titleShort: translate("ADDITIONAL_INFO_SHORT"),
      children: <AdditionalInfo onNext={handleNext} />,
    },
    {
      title: translate("ADD_WORK_ORDER_ADDRESS_AND_CONTACT"),
      titleShort: translate("ADDRESS"),
      children: <AddressAndContact onNext={handleNext} showHUL data={data} />,
    },
    // Meeting is temporary for 1.0
    // Calendar will be used for booking in future
    {
      title: translate("ADD_MEETING_TIME"),
      titleShort: translate("MEETING_TIME_SHORT"),
      children: <Meeting onNext={handleNext} data={data} setData={setData} />,
    },
    // {
    //   title: translate("CALENDAR"),
    //   titleShort: translate("BOOK_APPOINTMENT"),
    //   children: <Calendar />,
    // },
    {
      title: translate("ORDER_OVERVIEW"),
      titleShort: translate("ORDER_OVERVIEW_SHORT"),
      children: (
        <OrderOverview>
          <OrderInfo />
        </OrderOverview>
      ),
    },
  ];

  return (
    <WizardPage
      title={translate("ORDER_FITTING")}
      steps={steps}
      activeStep={setActiveStep(queryParams.content, currentActiveStep, data.currentStep)}
      setActiveStep={stepper.setActiveStep}
    />
  );
};

FittingPage.propTypes = {
  search: PropType.string,
  currentStep: PropType.number,
  currentActiveStep: PropType.number,
  fitting: PropType.object,
  product: PropType.object,
  text: PropType.string,
  subContentType: PropType.object,
  priority: PropType.object,
  address: PropType.object,
  contact: PropType.object,
  order: PropType.object,
};

export default withPage(FittingPage);
