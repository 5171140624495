import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Page from "../shared/Page/Page";
import LoginWelcome from "./Welcome/LoginWelcome";
import styles from "./StartPage.module.scss";
import ContentWelcome from "./Welcome/ContentWelcome";
import CompanyLogoList from "./CompanyLogo/HÖSCompanyLogo/CompanyLogoList";

const StartPage = ({ desiredPage }) => {
  const session = useSelector(state => state.session);
  let history = useHistory();
  const [, setRedirectTo] = useState(null);
  const loggedIn = !!session?.sessionId;

  useEffect(() => {
    if (history?.location?.state?.from) {
      setRedirectTo(history.location.state.from.pathname);
    }
  }, [history]);

  const title = "Välkommen";
  const text =
    "Här kan du förskriva hjälpmedel till personer med funktionsnedsättning.\n\nFör att få inloggningsuppgifter till portalen ska man kontakta budget/hjälpmedelsansvarig i sin aktuella kommun.";

  return (
    <Page customCssClass={styles.page}>
      {loggedIn ? (
        <ContentWelcome customCssClass={styles.welcomeSegment} />
      ) : (
        <LoginWelcome
          title={title}
          text={text}
          isLoggedIn={loggedIn}
          desiredPage={desiredPage}
          customCssClass={styles.welcomeSegment}
        />
      )}
      <CompanyLogoList customCssClass={styles.companyLogoSegment} />{" "}
    </Page>
  );
};

StartPage.propTypes = {
  desiredPage: PropTypes.string,
};
export default StartPage;
