import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import { useCallback, useEffect } from "react";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { ReactComponent as BankIDIcon } from "./assets/images/BankID_logo_black.svg";
import { useBankId } from "./hooks/useBankId";
import styles from "./style/bankId.module.scss";

// UI components
const BankIdButton = ({ onClick, text }) => (
  <div className={styles.content}>
    <button className={styles.button} onClick={onClick}>
      <BankIDIcon className={styles.icon} />
      <span className={styles.text}>{text}</span>
    </button>
  </div>
);

BankIdButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

const Spinner = ({ hide }) => {
  return <div className="spinnerPlaceholder">{hide ? <>Hiding</> : <LoadingSpinner />}</div>;
};
Spinner.propTypes = {
  hide: PropTypes.bool,
};

const FailedContent = ({ collectedResponse }) => {
  console.log("FailedContent, collectedResponse: ", collectedResponse);
  return (
    <div>
      <p>
        {collectedResponse.status === "failed" && collectedResponse.hintCode === "startFailed"
          ? "Inloggningen startades inte"
          : collectedResponse.status === "failed"
          ? "Autenticeringen misslyckades"
          : "Okänt fel"}
      </p>
    </div>
  );
};
FailedContent.propTypes = {
  collectedResponse: PropTypes.shape({
    status: PropTypes.string,
    hintCode: PropTypes.string,
  }).isRequired,
};

const PendingContent = ({ collectedResponse }) => {
  let msg;
  if (collectedResponse.status === "complete") {
    msg = "Inloggning lyckades, omdirigerar...";
  } else if (collectedResponse.status === "failed") {
    msg = "Inloggning misslyckades...";
  } else if (collectedResponse.hintCode === "userSign") {
    msg = "Väntar på inloggning...";
  } else {
    msg = "Väntar på BankID...";
  }

  return (
    <>
      <Spinner />
      <>{msg}</>
    </>
  );
};

PendingContent.propTypes = {
  collectedResponse: PropTypes.shape({
    status: PropTypes.string,
    hintCode: PropTypes.string,
  }).isRequired,
};

const InitContent = ({ qrCodeContent, autoStartToken, orderRef }) => {
  return (
    <>
      <ol>
        <li>1. Öppna BankID-appen i mobilen.</li>
        <li>2. Tryck på QR-symbolen i BankID-appen.</li>
        <li>3. Rikta kameran mot QR-koden i denna ruta.</li>
      </ol>
      <div className={styles.content}>{qrCodeContent && <QRCode size={128} value={qrCodeContent} />}</div>
      <div className={styles.content}>
        <a
          href={`bankid:///?autostarttoken=${autoStartToken}&redirect=https%3A%2F%2F${window.location.hostname}%2F%3FloginToken%3D${orderRef}`}
        >
          Logga in på denna enhet
        </a>
      </div>
    </>
  );
};
InitContent.propTypes = {
  qrCodeContent: PropTypes.string.isRequired,
  autoStartToken: PropTypes.string.isRequired,
  orderRef: PropTypes.string.isRequired,
};

const CancelContent = ({ handleReset }) => {
  return (
    <div className={styles.cancelContent} onClick={handleReset}>
      Stäng
    </div>
  );
};
CancelContent.propTypes = {
  handleReset: PropTypes.func.isRequired,
};

const BankIdContainer = ({ children, handleReset }) => {
  return (
    <div className={styles.authenticationContainer}>
      <div className={styles.bankIDContainerTitle}>
        <BankIDIcon className={styles.titleIcon} />
        <div className="bankIDHeading">Logga in med BankID</div>
      </div>
      {children}
      <CancelContent handleReset={handleReset} />
    </div>
  );
};

BankIdContainer.propTypes = {
  children: PropTypes.node.isRequired,
  handleReset: PropTypes.func.isRequired,
};

// Main component
const BankIdAuthentication = ({ isSelected }) => {
  const { orderRef, autoStartToken, initAuth, qrCodeContent, collectedResponse, resetFromUI } = useBankId();

  const handleReset = useCallback(() => {
    resetFromUI();
  }, [resetFromUI]);

  useEffect(() => {
    if (!isSelected) {
      handleReset();
    }
  }, [handleReset, isSelected]);

  return (
    <div className={styles.container}>
      {!autoStartToken ? (
        <BankIdButton onClick={initAuth} text="Logga in med BankID" />
      ) : (
        <BankIdContainer handleReset={handleReset}>
          {collectedResponse.hintCode === "outstandingTransaction" ? (
            <InitContent qrCodeContent={qrCodeContent} autoStartToken={autoStartToken} orderRef={orderRef} />
          ) : collectedResponse.hintCode === "expiredTransaction" || collectedResponse.status === "failed" ? (
            <FailedContent collectedResponse={collectedResponse} />
          ) : (
            <PendingContent collectedResponse={collectedResponse} />
          )}
        </BankIdContainer>
      )}
    </div>
  );
};
BankIdAuthentication.propTypes = {
  isSelected: PropTypes.bool.isRequired,
};

export default BankIdAuthentication;
