const pages = Object.freeze({
  BASE_STORAGES: {
    PATH: "/base-storages",
    NAME: "BASE_STORAGES",
  },
  BASE_STORAGE_INVENTORY_DETAIL: {
    PATH: "/base-storages/:baseStorage/products/:product",
    NAME: "BASE_STORAGE_INVENTORY_DETAIL",
  },
  BASE_STORAGE_INVENTORY: {
    PATH: "/base-storages/inventory",
    NAME: "BASE_STORAGE_INVENTORY",
  },
  CART: {
    PATH: "/orders/:order/cart",
    NAME: "CART",
  },
  CLIENTS: {
    PATH: "/clients",
    NAME: "CLIENTS_MENU",
  },
  CLIENT_INFO: {
    PATH: "/clients/:id/info",
    NAME: "SUB_NAV_CLIENT_INFO",
  },
  CLIENT_HOLDINGS: {
    PATH: "/clients/:id/holdings",
    NAME: "SUB_NAV_CLIENT_HOLDINGS",
  },
  CLIENT_ADDRESSES: {
    PATH: "/clients/:id/addresses",
    NAME: "SUB_NAV_CLIENT_ADDRESSES",
  },
  CLIENT_CONTACTS: {
    PATH: "/clients/:id/contacts",
    NAME: "SUB_NAV_CLIENT_CONTACTS",
  },
  CLIENT_TASKS: {
    PATH: "/clients/:id/tasks",
    NAME: "SUB_NAV_CLIENT_TASKS",
  },
  CLIENT_ORDERS: {
    PATH: "/clients/:id/orders",
    NAME: "SUB_NAV_CLIENT_ORDERS",
  },
  CLIENT_HOLDING_TRANSACTIONS: {
    PATH: "/clients/:id/holding-transactions",
    NAME: "CLIENT_HOLDING_TRANSACTIONS",
  },
  DEVIATION: {
    PATH: "/deviation",
    NAME: "DEVIATION_TITLE",
  },
  DOCUMENTS: {
    PATH: "/documents",
    NAME: "DOCUMENTS_TITLE",
  },
  EMBEDDED_MY_MESSAGES: {
    PATH: "/embedded/messages",
  },
  EMBEDDED_MY_FITTINGS: {
    PATH: "/embedded/my-myloc/fittings",
  },
  EMBEDDED_MY_FITTING: {
    PATH: "/embedded/my-myloc/fittings/:fitting/:type",
  },
  EMBEDDED_PRODUCTS: {
    PATH: "/embedded/products",
  },
  EMBEDDED_PRODUCT_DETAIL: {
    PATH: "/embedded/products/:product",
  },
  EMBEDDED_PRODUCT_ORDER: {
    PATH: "/embedded/orders/:order/products/:content",
  },
  EMBEDDED_WORK_ORDER: {
    PATH: "/embedded/orders/:order/work/:content?",
  },

  FIXED_ASSET: {
    PATH: "/base-storages/:baseStorage/fixed-assets/:product",
    NAME: "BASE_STORAGE_INVENTORY",
  },
  FIXED_ASSET_INVENTORY: {
    PATH: "/inventories/:product",
    NAME: "INVENTORY_DETAIL",
  },
  FUNCTION_ORDER: {
    PATH: "/orders/:order/functions/:content?",
    NAME: "FUNCTION_ORDER_MENU",
  },
  HOME: "/",
  MESSAGES: {
    PATH: "/messages",
    NAME: "MESSAGES_TITLE",
  },
  MESSAGES_INBOX: {
    PATH: "/messages/inbox",
    NAME: "MESSAGES_INBOX_TITLE",
  },
  MESSAGES_SENT: {
    PATH: "/messages/sent",
    NAME: "MESSAGES_SENT_TITLE",
  },
  MESSAGES_ARCHIVED: {
    PATH: "/messages/archived",
    NAME: "MESSAGES_ARCHIVED_TITLE",
  },
  MESSAGES_ALL: {
    PATH: "/messages/all",
    NAME: "MESSAGES_ALL_TITLE",
  },
  MY_MYLOC: {
    PATH: "/my-myloc",
    NAME: "MY_MYLOC_TITLE",
  },
  INVENTORIES: {
    PATH: "/inventories",
    NAME: "SEARCH_FOR_INVENTORY",
  },
  ORDER_CONFIRMATION: {
    PATH: "/order-sent",
    NAME: "ORDER_CONFIRMATION_TITLE",
  },
  ORDERS: {
    PATH: "/orders",
    NAME: "ORDERS_TITLE",
  },
  ORDER_DETAIL: {
    PATH: "/orders/:order",
    NAME: "ORDER_DETAIL_TITLE",
  },
  PICKUP_ORDER: {
    PATH: "/orders/:order/pick-ups/:content?",
    NAME: "PICKUP_ORDER_MENU",
  },
  PRODUCTS: {
    PATH: "/products",
    NAME: "PRODUCTS_TITLE",
  },
  PRODUCT_DETAIL: {
    PATH: "/products/:product",
    NAME: "PRODUCTS_MENU",
  },
  PRODUCTS_EXTERNAL_ASSORTMENT: {
    PATH: "/orders/:order/external-assortments/:content?",
    NAME: "MENU_EXTERNAL_ASSORTMENT",
  },
  PRODUCT_ORDER: {
    PATH: "/orders/:order/products/:content?",
    NAME: "PRODUCT_ORDER_TITLE",
  },
  RECEIVE_DELIVERIES: {
    PATH: "/receive/delivery",
    NAME: "RECEIVE_DELIVERIES_TITLE",
  },
  RECEIVE_DELIVERY_ORDER: {
    PATH: "/receive/delivery/order/:type/:supplier/:product/:baseStorage",
    NAME: "RECEIVE_DELIVERY_ORDER_TITLE",
    TYPE: {
      LOT_CONTROLLED: "lot-controlled",
      NOT_LOT_CONTROLLED: "not-lot-controlled",
      FIXED_ASSET_CONTROLLED: "fixed-asset-controlled",
    },
  },
  REGISTER: "account/register",
  RESET_PASSWORD: {
    PATH: "/password/reset",
    NAME: "RESET_PASSWORD_TITLE",
  },
  FITTING: {
    PATH: "/orders/:order/fittings/:content?",
    NAME: "FITTING_MENU",
  },
  FITTING_DETAIL: {
    PATH: "/my-myloc/fittings/:fitting",
    NAME: "FITTING_DETAIL_TITLE",
  },
  FITTINGS: {
    PATH: "/my-myloc/fittings",
    NAME: "SUB_NAV_MY_FITTINGS",
  },
  USER_SETTINGS: {
    PATH: "/settings",
    NAME: "USER_SETTINGS",
  },
  MY_TASKS: {
    PATH: "/my-myloc/tasks",
    NAME: "SUB_NAV_MY_WORK",
  },
  MY_ORDERS: {
    PATH: "/my-myloc/orders",
    NAME: "SUB_NAV_MY_ORDERS",
  },
  MY_FITTINGS: {
    PATH: "/my-myloc/fittings",
    NAME: "SUB_NAV_MY_FITTINGS",
  },
  MY_DEVIATIONS: {
    PATH: "/my-myloc/deviations",
    NAME: "SUB_NAV_MY_DEVIATIONS",
  },
  MY_DEVIATION_DETAILS: {
    PATH: "/my-myloc/deviations/:deviation",
    NAME: "SUB_NAV_MY_DEVIATION_DETAILS",
  },
  MY_PROFILE: {
    PATH: "/my-myloc/profile",
    NAME: "SUB_NAV_MY_PROFILE",
  },
  WORK_ORDER: {
    PATH: "/orders/:order/work/:content?",
    NAME: "WORK_ORDER_MENU",
  },
  MY_DEVIATIONS_INFO: {
    PATH: "/my-myloc/deviations/:deviation/info",
    NAME: "SUB_NAV_MY_DEVIATIONS_INFO",
  },
  MY_DEVIATIONS_ATTACHMENTS: {
    PATH: "/my-myloc/deviations/:deviation/attachments",
    NAME: "SUB_NAV_MY_DEVIATIONS_ATTACHMENTS",
  },
  MY_FITTINGS_INFO: {
    PATH: "/my-myloc/fittings/:fitting/info",
    NAME: "FITTING_SUB_NAV_INFO",
  },
  MY_FITTINGS_USER_AND_CONTACT: {
    PATH: "/my-myloc/fittings/:fitting/user-and-contact",
    NAME: "FITTING_SUB_NAV_USER_AND_CONTACT",
  },
  MY_FITTINGS_CALENDAR: {
    PATH: "/my-myloc/fittings/:fitting/calendar",
    NAME: "FITTING_SUB_NAV_CALENDAR",
  },
  MY_FITTINGS_NEXT_MEETING: {
    PATH: "/my-myloc/fittings/:fitting/next-meeting",
    NAME: "FITTING_SUB_NAV_NEXT_MEETING",
  },
  MY_FITTINGS_PREVIOUS_MEETING: {
    PATH: "/my-myloc/fittings/:fitting/previous-meetings",
    NAME: "FITTING_SUB_NAV_PREVIOUS_MEETINGS",
  },
  MY_FITTINGS_OTHER_INFO: {
    PATH: "/my-myloc/fittings/:fitting/other-info",
    NAME: "FITTING_SUB_NAV_OTHER_INFO",
  },
  MY_FITTINGS_COMMENTS: {
    PATH: "/my-myloc/fittings/:fitting/comments",
    NAME: "FITTING_SUB_NAV_COMMENTS",
  },
  MY_FITTINGS_ATTACHMENTS: {
    PATH: "/my-myloc/fittings/:fitting/attachments",
    NAME: "FITTING_SUB_NAV_ATTACHMENTS",
  },
  MY_FITTINGS_ORDERS: {
    PATH: "/my-myloc/fittings/:fitting/orders",
    NAME: "FITTING_SUB_NAV_ORDERS",
  },
  MY_FITTINGS_EVENTS: {
    PATH: "/my-myloc/fittings/:fitting/events",
    NAME: "FITTING_SUB_NAV_EVENTS",
  },
});

export default pages;
