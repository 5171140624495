import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import defaultRestOptions from "../../../../services/utils/defaultRestOptions";
import { api } from "../config/settings";

export async function initiateAuth() {
  const url = api.bankid.initiateAuth();
  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions());
  return response;
}

export async function collect(orderRef) {
  const url = api.bankid.collect(orderRef);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, orderRef, await defaultRestOptions());
  return response;
}
