import {
  SEARCH_STRING,
  SELECTED_CATEGORY,
  TOGGLE_SELECTED_COMPANY,
  CLEAR_SELECTED_COMPANIES,
  SAVE_SCROLL_POSITION,
  REMOVE_SCROLL_POSITION,
  CONTENT_EMBEDDED,
} from "./settingVariables";

const initialState = {
  searchString: "",
  selectedCategory: "",
  selectedCompanies: [],
  savedScrollPositions: [],
  contentEmbedded: null,
};

const settingReducer = (state = initialState, action) => {
  let newSelectedCompanies = "";
  switch (action.type) {
    case SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      };
    case TOGGLE_SELECTED_COMPANY:
      newSelectedCompanies = [...state.selectedCompanies];
      if (newSelectedCompanies.indexOf(action.selectedCompany) > -1) {
        newSelectedCompanies.splice(newSelectedCompanies.indexOf(action.selectedCompany), 1);
      } else {
        newSelectedCompanies.push(action.selectedCompany);
      }
      return {
        ...state,
        selectedCompanies: newSelectedCompanies,
      };
    case SAVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.concat({ key: action.key, position: action.position }),
      };
    case REMOVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.filter(scrollPosition => scrollPosition.key !== action.key),
      };
    case CLEAR_SELECTED_COMPANIES:
      return {
        ...state,
        selectedCompanies: [],
      };
    case CONTENT_EMBEDDED:
      return {
        ...state,
        contentEmbedded: action.contentEmbedded,
      };
    default:
      return state;
  }
};

export default settingReducer;
