import {
  useState,
  //  useEffect
} from "react";
import { Button } from "@myloc/myloc-gui";
import DropMenu from "./Dropmenu";
import styles from "../FittingDetailPage.module.scss";

const Comments = () => {
  // const [data, setData] = useState({ comments: [] });
  const [message, setMessage] = useState("");

  // useEffect(() => {
  //   (async () => {
  //     const response = await orderService.getComments({});
  //     setData({ comments: response.data });
  //   })();
  // }, []);

  const isAuthor = user => {
    return user.id === "user-id-123";
  };

  const data = {
    comments: [
      {
        id: "comment-id-123",
        user: {
          id: "user-id-123",
          name: "John Doe",
        },
        message:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quis quam ornare justo auctor molestie.",
        created_at: "2099-01-01 12:00:00",
      },
      {
        id: "comment-id-456",
        user: {
          id: "user-id-456",
          name: "Jane Doe",
        },
        message:
          "Ut ultrices risus ac nibh gravida varius. Donec pulvinar purus vel ullamcorper facilisis. Aenean ac molestie orci. Vestibulum vel orci at risus condimentum gravida.",
        created_at: "2099-01-01 12:00:00",
      },
    ],
  };

  return (
    <div>
      <section className={styles.message}>
        <div
          contentEditable="true"
          className={styles.textarea}
          onInput={event => setMessage(event.currentTarget.textContent)}
        />
      </section>
      <div className={styles.action}>
        <span className={styles.limit}>{message.length} / 250</span>
        <Button>Skicka</Button>
      </div>

      {data.comments.map(comment => {
        return (
          <div key={comment.id} className={styles.commentCard}>
            <div className={styles.header}>
              <span className={styles.author}>{comment.user.name}</span>
              <div className={styles.right}>
                <span className={styles.timestamp}>{comment.created_at}</span>
                <DropMenu className={isAuthor(comment.user) ? styles.hidden : ""} />
              </div>
            </div>

            <div className={styles.message}>{comment.message}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Comments;
