export const DATE = "date";
const TEN = 10;

function getMonth(date) {
  const month = date.getMonth() + 1;

  return month < TEN ? `0${month}` : month;
}

function getDate(date) {
  const day = date.getDate();

  return day < TEN ? `0${day}` : day;
}

function getHours(date) {
  const hour = date.getHours();

  return hour < TEN ? `0${hour}` : hour;
}

function getMinutes(date) {
  const minutes = date.getMinutes();

  return minutes < TEN ? `0${minutes}` : minutes;
}

export function getFullDate(timestamp) {
  if (!timestamp) return "";

  const date = new Date(timestamp);

  return timestamp ? `${date.getFullYear()}-${getMonth(date)}-${getDate(date)}` : "";
}

export function getTimestamp(timestamp) {
  if (timestamp) {
    const date = new Date(timestamp);

    return timestamp
      ? `${date.getFullYear()}-${getMonth(date)}-${getDate(date)} ${getHours(date)}:${getMinutes(date)}`
      : "";
  }

  return "";
}
