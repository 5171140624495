import CryptoJS from "crypto-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { collect, initiateAuth } from "../services/bankIdService";

// Custom hook for handling BankID authentication
export const useBankIdStartAuthentication = setBankIdError => {
  const [orderRef, setOrderRef] = useState("");
  const [autoStartToken, setAutoStartToken] = useState("");
  const [bankIdContent, setBankIdContent] = useState("");

  const resetAuth = () => {
    setAutoStartToken("");
  };

  const handleAuth = async () => {
    const response = await initiateAuth();
    if (!response.isOk()) {
      const msg = typeof response.message === "string" ? response.message : response.message.text;
      setBankIdError(msg);
      return;
    }

    setBankIdContent(response.data);
    setOrderRef(response.data.orderRef);
    setAutoStartToken(response.data.autoStartToken);
  };

  return { orderRef, autoStartToken, bankIdContent, handleAuth, resetAuth };
};

// Custom hook for generating and managing QR code
export const useQRCode = bankIdContent => {
  const [qrCodeContent, setQrCodeContent] = useState("");
  const intervalRef = useRef();

  const stopQRGeneration = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setQrCodeContent("");
    }
  }, []);

  const bankIdGenerateQRCode = useCallback(
    time => {
      if (!bankIdContent) return;
      const qrAuthCode = CryptoJS.HmacSHA256("" + time, bankIdContent.qrStartSecret);
      const qrContent = "bankid." + bankIdContent.qrStartToken + "." + time + "." + qrAuthCode;
      setQrCodeContent(qrContent);
    },
    [bankIdContent],
  );

  useEffect(() => {
    if (!bankIdContent) return stopQRGeneration();

    const nowMS = new Date().getTime();
    const timeFromReceivedMS = nowMS - bankIdContent.receivedTime;
    let timeFromReceived = Math.round(timeFromReceivedMS / 1000);

    intervalRef.current = setInterval(() => {
      bankIdGenerateQRCode(timeFromReceived);
      timeFromReceived += 1;
      if (timeFromReceived > 60) {
        stopQRGeneration();
      }
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [bankIdContent, bankIdGenerateQRCode, stopQRGeneration]);

  return { qrCodeContent, stopQRGeneration };
};

const collectedResponseInitialValues = {
  status: "",
  hintCode: "",
};

// Custom hook for checking the collection status of the BankID process
export const useBankIDCollect = (bankIdContent, setBankIdError) => {
  const [collectedResponse, setCollectedResponse] = useState(collectedResponseInitialValues);
  const intervalCollectRef = useRef();

  const resetCollectedResponse = () => {
    setCollectedResponse(collectedResponseInitialValues);
  };

  const stopCollectTimer = useCallback(() => {
    if (intervalCollectRef.current) {
      clearInterval(intervalCollectRef.current);
      return;
    }
  }, []);

  const resetCollect = useCallback(() => {
    stopCollectTimer();
    resetCollectedResponse();
  }, [stopCollectTimer]);

  const validateBankIdCollect = useCallback(
    async setBankIDError => {
      const response = await collect({ orderRef: bankIdContent.orderRef });

      if (!response.isOk()) {
        const msg = typeof response.message === "string" ? response.message : response.message.text;
        setBankIDError(msg);

        return;
      }
      setCollectedResponse(response.data);

      //Do continue to collect status until not 'pending' anymore - will turn into success or failure
      if (response.data.status !== "pending") {
        if (intervalCollectRef.current) {
          stopCollectTimer();
        }
      }
    },
    [bankIdContent.orderRef, stopCollectTimer],
  );

  useEffect(() => {
    if (!bankIdContent?.orderRef) return;

    // Set up the interval to call validateBankIdCollect every second
    intervalCollectRef.current = setInterval(() => {
      validateBankIdCollect(setBankIdError);
    }, 2000);

    // Cleanup function to clear the interval
    return () => {
      if (intervalCollectRef.current) {
        stopCollectTimer();
      }
    };
  }, [bankIdContent, setBankIdError, stopCollectTimer, validateBankIdCollect]);

  useEffect(() => {
    if (collectedResponse?.status === "complete") {
      window.location.href = "/?loginToken=" + bankIdContent.orderRef;
    }
  }, [collectedResponse, bankIdContent]);

  return { collectedResponse, resetCollect };
};
