import { HEADER, HeaderOptions, RestOptions } from "@myloc/myloc-utils";
import i18n from "../../language/i18n";
import storage from "../../utils/store/storage";
import { SESSION_ID, SINGLE_SIGN_ON_RESPONSE } from "../../utils/store/storeVariables";
import { handleError } from "../error/errorService";

const SSO_HEADER_OPTIONS = {
  ISS: "OIDC_CLAIM_iss",
  UPN: "OIDC_CLAIM_upn",
  GIVEN_NAME: "OIDC_CLAIM_given_name",
  FAMILY_NAME: "OIDC_CLAIM_family_name",
  AUD: "OIDC_CLAIM_aud",
  SUB: "OIDC_CLAIM_sub",
  TID: "OIDC_CLAIM_tid",
  UNIQUE_NAME: "OIDC_CLAIM_unique_name",
};

/**
 * Pass a custom Error handler function when needed - see customErrorHandler example in accountService.resetPassword
 * @param {*} customErrorHandler
 * @returns RestOptions
 */
const defaultRestOptions = async customErrorHandler => {
  const sessionId = await storage.loadItem(SESSION_ID);
  const options = new RestOptions();

  if (customErrorHandler) {
    options.errorService = customErrorHandler;
  } else {
    options.errorService = handleError;
  }

  options.headerOptions = new HeaderOptions();
  if (sessionId) options.headerOptions.setHeader(HEADER.SESSIONID, sessionId);

  const isSingleSignOn = await storage.loadItem(SINGLE_SIGN_ON_RESPONSE);

  if (isSingleSignOn) {
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.ISS, isSingleSignOn.iss);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.UPN, isSingleSignOn.upn);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.GIVEN_NAME, isSingleSignOn.given_name);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.FAMILY_NAME, isSingleSignOn.family_name);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.AUD, isSingleSignOn.aud);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.SUB, isSingleSignOn.sub);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.TID, isSingleSignOn.tid);
    options.headerOptions.setHeader(SSO_HEADER_OPTIONS.UNIQUE_NAME, isSingleSignOn.unique_name);
  }

  options.headerOptions.setHeader(HEADER.LANGUAGE, i18n.language);

  return options;
};

export default defaultRestOptions;
