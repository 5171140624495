import { ORDER_POPUP, HOLDING_POPUP } from "./dialogVariable";

const initialState = {
  orderPopup: {
    visible: false,
    includeNavigation: true,
    receiverType: "",
  },
};

export const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_POPUP:
      return Object.assign({}, state, { orderPopup: action.orderPopup });
    case HOLDING_POPUP:
      return Object.assign({}, state, { holdingPopup: action.holdingPopup });
    default:
      return state;
  }
};
