// useSelectedLoginMethod.js
import { useCallback, useState } from "react";
import { LOGIN_METHODS } from "./useAvailableLoginMethods";

const useSelectedLoginMethod = addLoginMethod => {
  const [selectedLoginMethod, setSelectedLoginMethod] = useState(undefined);

  const activateAndSelectLoginMethod = useCallback(
    loginMethod => {
      addLoginMethod(loginMethod);
      setSelectedLoginMethod(loginMethod);
    },
    [addLoginMethod],
  );

  const reset = () => {
    setSelectedLoginMethod(undefined);
  };

  const isLoginFormSelected = () => selectedLoginMethod === LOGIN_METHODS.LOGIN_FORM;
  const isBankIDSelected = () => selectedLoginMethod === LOGIN_METHODS.BANKID;

  return { selectedLoginMethod, activateAndSelectLoginMethod, reset, isLoginFormSelected, isBankIDSelected };
};

export default useSelectedLoginMethod;
